<template>
    <v-card>
        <v-app-bar dense>
            <v-toolbar-title>
                MWW Options
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip v-if="editOptions" bottom>
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" v-if="editOptions" @click.native="editOptions = !editOptions" slot="activator">
                        <v-icon small color="teal">far fa-edit</v-icon>
                    </v-btn>
                </template>
                <span>Edit</span>
            </v-tooltip>
            <v-tooltip v-if="!editOptions" bottom>
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" v-if="!editOptions" @click.native="saveChanges()" slot="activator">
                        <v-icon small color="green">far fa-save</v-icon>
                    </v-btn>
                </template>
                <span>Save</span>
            </v-tooltip>
        </v-app-bar>
        <v-card-text>
            <v-layout row wrap>
                <v-divider></v-divider>
                <v-flex xs12>
                    <label class="head_label">Language Preference</label>
                </v-flex>
                <v-flex xs12>
                    <v-select color="primary"
                              solo
                              dense
                              :disabled="true"
                              v-model="language"
                              :items="languages"></v-select>
                </v-flex>
                <v-flex xs12>
                    <label class="head_label">Country</label>
                </v-flex>
                <v-flex xs12>
                    <v-autocomplete color="primary"
                                    solo
                                    dense
                                    :disabled="editOptions"
                                    v-model="country"
                                    :items="countries"></v-autocomplete>
                </v-flex>

                

                <!--Snackbar-->
                <v-snackbar v-model="snackbar"
                            left>
                    {{ snackbarMessage }}

                    <template v-slot:action="{ attrs }">
                        <v-btn dark
                               text
                               v-bind="attrs"
                               @click="snackbar = false">
                            Close
                        </v-btn>
                    </template>
                </v-snackbar>
                <!--End Of Snackbar-->

            </v-layout>
        </v-card-text>
    </v-card>
</template>

<script>
    import countryJson from '../../../json/countries.json'

    export default {
        data() {
            return {
                saveOptions: false,
                editOptions: true,
                currencyDialog: false,
                model: null,
                snackbarMessage: '',
                snackbar: '',
                gct: 16.5,
                country: 'Jamaica',
                language: 'English',
                countries: [],
                languages: ['English', 'Spanish', 'French'],
                
            }
        },
        methods: {
            updateCurrency: function () {
                for (let i = 0; i < this.currencies.length; i++) {
                    for (let x = 0; x < this.currency.length; x++) {
                        if (this.currency[x] === this.currencies[i]) {
                            if (this.currencies[i].ObjectState === "Deleted") {
                                this.currencies[i].ObjectState = "Current"
                            }
                            break
                        }
                        if (x === this.currency.length && this.currencies[i].ObjectState !== "Deleted") {
                            this.currencies[i].ObjectState = "Deleted"
                        }
                    }
                }
            },
            saveChanges: function () {
                this.saveOptions = !this.saveOptions;
                this.editOptions = !this.editOptions;
                this.snackbar = true;
                this.snackbarMessage = 'Options Updated'
            },

        },
        beforeMount() {
            this.countries = countryJson
        },

    }
</script>